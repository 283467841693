import React, { useRef, useEffect } from "react";
import Img from "gatsby-image";
import "../style/font-style.css";
import { Box, Grid, Hidden } from "@material-ui/core";
import { useStaticQuery, graphql, Link } from "gatsby";
import WrapTypo from "../components/WrapTypo";
import Head from "../components/head";
import Header from "../components/Header_normal";
import Footer from "../components/footer";

const Concept = () => {
  const data = useStaticQuery(graphql`
    query {
      saw: file(relativePath: { eq: "saw.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
      mtchokai: file(relativePath: { eq: "mtchokai.png" }) {
        childImageSharp {
          fluid(maxWidth: 800) {
            ...GatsbyImageSharpFluid
          }
        }
      }
    }
  `);
  return (
    <>
      <Header isBlack={true} />
      <Box>
        <Hidden smDown>
          <Box
            height="350px"
            style={{
              writingMode: "vertical-rl",
              textOrientation: "upright"
            }}
            m={"auto"}
            mt={20}
            mb={10}
          >
            <WrapTypo fw="500" ls="10px" lh="250%" fs="24px" ml="25%">
              わたしたちの信念
            </WrapTypo>
            <WrapTypo lh="250%" fs="18px" ml="20%">
              知っていますか。
              <br />
              同じ設計図、同じ素材でも、
              <br />
              違う強度の家ができてしまうこと。
            </WrapTypo>

            <WrapTypo
              lh="250%"
              fs="16px"
              textAlign="justify"
              style={{
                writingMode: "vertical-rl",
                textOrientation: "upright"
              }}
            >
              突き詰めると、わずかな差の積み重ねです。木材にも上下がありますし、断熱材はふっくら柔らかに敷き詰めたほうが良い。板が割れないようにネジの締めは9割七分まで。きちんとした知識があるかどうか、きちんとそれを再現できるかどうかが大切なのです。一つ一つの作業に妥協を許さない。
              <br />
              それが、HAYAFUJIブランドです。
            </WrapTypo>
          </Box>
          <Img fluid={data.mtchokai.childImageSharp.fluid} />
          <Box
            m={"auto"}
            height="350px"
            style={{
              writingMode: "vertical-rl",
              textOrientation: "upright"
            }}
            my={20}
          >
            <WrapTypo fw="500" ls="10px" lh="250%" fs="24px" ml="25%">
              大切にしていること
            </WrapTypo>
            <WrapTypo
              lh="250%"
              fs="16px"
              textAlign="justify"
              style={{
                writingMode: "vertical-rl",
                textOrientation: "upright"
              }}
            >
              建築の世界において、早熟の天才はいないと私達は考えております。設計士も職人も日々の経験と修行があって納得のいく仕事ができるのだと思います。一つ一つの仕事を丁寧に対応していくことを会社の経営理念としています。デザインだけにこだわりすぎた、使い勝手の悪い家。価格だけにとらわれ、平凡で面白みに欠ける家。私達の目指すところはどちらでもなく、完全自由設計により一件一件、お客様のニーズにしっかりと答えた上でHAYAFUJIらしさ、遊びごごろを持って設計し、ご提案したいと考えております。
            </WrapTypo>
            <WrapTypo
              lh="250%"
              fs="16px"
              textAlign="justify"
              style={{
                writingMode: "vertical-rl",
                textOrientation: "upright"
              }}
            >
              その取り組みが何気ない日常に彩を与得ることができたら、幸いです。どんなに施工性の良い建材や利便性に優れた工法を採用したとしても、家を造るのは人間です。必ず造り手の質の違いはあります。小さい頃から職人の世界を見てきたからこそ職人をリスペクトし、信頼できる自社の職人と仕事をすることを基本としております。
            </WrapTypo>
          </Box>
          <hr width="30%" style={{ margin: "auto" }} />
          <Box
            height="350px"
            m={"auto"}
            style={{
              writingMode: "vertical-rl",
              textOrientation: "upright"
            }}
            my={20}
          >
            <WrapTypo fw="500" ls="10px" lh="250%" fs="24px">
              家をたてるという事
            </WrapTypo>
            <WrapTypo
              mr="100%"
              lh="250%"
              fs="16px"
              textAlign="justify"
              style={{
                writingMode: "vertical-rl",
                textOrientation: "upright"
              }}
            >
              人生の中で一番い大きい買い物が家を建てる事です。一言では言い表す事のできない、様々な想いを持って家を建てる決心をされていると思います。だからこそ後悔はさせたくない、お客様には必ず満足してもらいたい。そのためにはお客様のライフスタイル・趣味嗜好をしっかりと理解する必要ことが何より大事だと考えています。お客様の中にあるイメージを形として創造する事が私たちの仕事。つまりは、計画から完成、そしてその後も引き続き信頼関係を築いていくことを一番大切にしております。
              <br />
              <br />
              代表取締役　早藤　亮
            </WrapTypo>
          </Box>
        </Hidden>
        <Hidden mdUp>
          <Box m={"auto"} width="90%" maxWidth="600px" my={20} textAlign="left">
            <WrapTypo
              fw="500"
              ls="10px"
              lh="250%"
              fs="24px"
              style={{ textAlign: "left" }}
            >
              わたしたちの信念
            </WrapTypo>
            <WrapTypo
              lh="250%"
              fs="18px"
              mt={"40px"}
              style={{ textAlign: "left" }}
            >
              知っていますか。
              <br />
              同じ設計図、同じ素材でも、
              <br />
              違う強度の家ができてしまうこと。
            </WrapTypo>
            <WrapTypo
              lh="200%"
              fs="16px"
              style={{ textAlign: "justify" }}
              mt={"40px"}
            >
              突き詰めると、わずかな差の積み重ねです。
              木材にも上下がありますし、断熱材はふっくら柔らかに敷き詰めたほうが良い。
              板が割れないようにネジの締めは9割七分まで。
              きちんとした知識があるかどうか、きちんとそれを再現できるかどうかが大切なのです。
              一つ一つの作業に妥協を許さない。
              <br />
              <br />
              それが、HAYAFUJIブランドです。
            </WrapTypo>
          </Box>
          <Img fluid={data.mtchokai.childImageSharp.fluid} />
          <Box m={"auto"} width="90%" maxWidth="600px" my={20} textAlign="left">
            <WrapTypo
              fw="500"
              ls="10px"
              lh="250%"
              fs="24px"
              style={{ textAlign: "left" }}
            >
              大切にしていること
            </WrapTypo>
            <WrapTypo
              lh="200%"
              fs="16px"
              style={{ textAlign: "justify" }}
              mt={"40px"}
            >
              建築の世界において、早熟の天才はいないと私達は考えております。設計士も職人も日々の経験と修行があって納得のいく仕事ができるのだと思います。一つ一つの仕事を丁寧に対応していくことを会社の経営理念としています。
              デザインだけにこだわりすぎた、使い勝手の悪い家。価格だけにとらわれ、平凡で面白みに欠ける家。私達の目指すところはどちらでもなく、完全自由設計により一件一件、お客様のニーズにしっかりと答えた上でHAYAFUJIらしさ、遊びごごろを持って設計し、ご提案したいと考えております。
              <br />
              <br />
              その取り組みが何気ない日常に彩を与得ることができたら、幸いです。
              どんなに施工性の良い建材や利便性に優れた工法を採用したとしても、家を造るのは人間です。必ず造り手の質の違いはあります。小さい頃から職人の世界を見てきたからこそ職人をリスペクトし、信頼できる自社の職人と仕事をすることを基本としております。
            </WrapTypo>
          </Box>
          <Box m={"auto"} width="90%" maxWidth="600px" my={20} textAlign="left">
            <WrapTypo
              fw="500"
              ls="10px"
              lh="250%"
              fs="24px"
              style={{ textAlign: "left" }}
            >
              家をたてるという事
            </WrapTypo>
            <WrapTypo
              lh="200%"
              fs="16px"
              style={{ textAlign: "justify" }}
              mt={"40px"}
            >
              人生の中で一番い大きい買い物が家を建てる事です。一言では言い表す事のできない、様々な想いを持って家を建てる決心をされていると思います。だからこそ後悔はさせたくない、お客様には必ず満足してもらいたい。そのためにはお客様のライフスタイル・趣味嗜好をしっかりと理解する必要ことが何より大事だと考えています。お客様の中にあるイメージを形として創造する事が私たちの仕事。つまりは、計画から完成、そしてその後も引き続き信頼関係を築いていくことを一番大切にしております。
              <br />
              <br />
              <br />
              代表取締役　早藤　亮
            </WrapTypo>
          </Box>
        </Hidden>
      </Box>
      <Footer />
    </>
  );
};

export default Concept;
